<template>
    <WindowLayout :title="title">
        <div class="row">
            <div class="col-sm-12">
                <div class="darmin card">
                    <div class="card-header pb-0 d-flex align-items-center">
                        <h5>{{ headerTitle }}</h5>
                    </div>
                    <div class="card-body theme-form">
                        <div class="row">
                            <div class="col">
                                <div class="mb-3 row">
                                    <label class="col-sm-3 col-form-label">Nombre</label>
                                    <div class="col-sm-9">
                                        <input
                                            class="form-control"
                                            type="text"
                                            v-model="state.name"
                                        />
                                    </div>
                                </div>
                                <div class="mb-3 row">
                                    <label class="col-sm-3 col-form-label">Descripcion</label>
                                    <div class="col-sm-9">
                                        <textarea
                                            class="form-control"
                                            type="text"
                                            v-model="state.desc"
                                        />
                                    </div>
                                </div>
                                <div class="mb-3 row">
                                    <label class="col-sm-3 col-form-label"
                                        >Modo de renderizado</label
                                    >
                                    <div class="col-sm-9">
                                        <select
                                            class="form-select btn-square digits"
                                            v-model="state.mode"
                                        >
                                            <option disable></option>
                                            <option value="1">Merge Files Uploaded</option>
                                            <option value="2">With a Template</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="mb-3 row">
                                    <label class="col-sm-3 col-form-label">Estado</label>
                                    <div class="col-sm-9">
                                        <SelectInputSimpleState v-model="state.status" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-12">
                <div class="darmin card header-card">
                    <div class="row">
                        <div class="col-12 text-end">
                            <div
                                class="btn btn-primary ms-3 mb-1"
                                @click="handleAction('save')"
                                v-if="state.id"
                            >
                                <i class="far fa-save"></i>
                                Guardar
                            </div>
                            <div
                                v-if="!state.id"
                                class="btn btn-primary ms-3 mb-1"
                                @click="handleAction('new')"
                            >
                                <i class="far fa-save"></i>
                                Crear
                            </div>
                            <div
                                v-if="!state.id"
                                class="btn btn-primary ms-3 mb-1"
                                @click="handleAction('new_view')"
                            >
                                <i class="far fa-save"></i>
                                Crear y mostrar
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </WindowLayout>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { reactive, ref, onMounted, computed } from 'vue';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { useStore } from 'vuex';
// Components
import { useSnackbar } from 'vue3-snackbar';
import WindowLayout from '@/layouts/Window.layout.vue';
import SelectInputSimpleState from '@/components/form/SelectInputSimpleState.vue';

// Services
import useExamen from './services/useExamen';

export default {
    name: 'PacienteForm',
    components: {
        WindowLayout,
        SelectInputSimpleState,
    },
    props: {
        title: { type: String, default: 'Examen' },
        headerTitle: { type: String, default: 'Ingresar Examen' },
        item: Object,
    },
    setup(props) {
        const store = useStore();
        const isReady = ref(false);
        const snackbar = useSnackbar();
        const state = reactive({
            id: '',
            consecutive: '',
            name: '',
            desc: '',
            mode: '',
            status: 1,
        });

        // eslint-disable-next-line object-curly-newline
        const { examen, getExamen, createExamen, updateExamen } = useExamen();

        /** ***** Autocompleters ***** */

        /** ***** Helpers ***** */
        const setState = () => {
            state.id = examen.value.id;
            state.consecutive = examen.value.consecutive;
            state.name = examen.value.name;
            state.desc = examen.value.desc;
            state.mode = examen.value.mode;
            state.status = examen.value.status.toString();
        };

        const resetWindow = () => {
            state.id = '';
            state.consecutive = '';
            state.name = '';
            state.desc = '';
            state.mode = '';
            state.status = 1;

            examen.value = null;
        };

        /** ***** Lifecycle Hooks ***** */
        onMounted(async () => {
            if (props.item) {
                await Promise.all([getExamen(props.item.id)]);
                if (examen.value) {
                    setState();
                }
            }
            isReady.value = true;
        });

        /** ***** Validator ***** */
        const rules = computed(() => ({
            name: { required },
            mode: { required },
            status: { required },
        }));
        const $v = useVuelidate(rules, state);

        const handleAction = async (action) => {
            await $v.value.$validate();
            if (!$v.value.$error) {
                // No errors
                let text;
                switch (action) {
                    case 'new':
                    case 'new_view':
                        await createExamen({
                            name: state.name,
                            desc: state.desc,
                            mode: state.mode,
                            status: state.status,
                        });
                        if (action === 'new_view') {
                            store.dispatch('ContentManager/openWindow', {
                                id: `ExamenForm_${examen.value.id}`,
                                component: 'ExamenForm',
                                name: 'Examen',
                                params: {
                                    title: 'Examen',
                                    headerTitle: 'Editar Examen',
                                    item: { ...examen.value },
                                },
                            });
                        }
                        resetWindow();
                        text = 'Examen ingresado';
                        break;
                    case 'save':
                        await updateExamen(state.id, {
                            name: state.name,
                            desc: state.desc,
                            mode: state.mode,
                            status: state.status,
                        });
                        text = 'Examen actualizado';
                        break;
                    default:
                        break;
                }
                snackbar.add({
                    type: 'success',
                    text,
                });
                setState();
            } else {
                console.log($v.value.$errors);
            }
        };

        return {
            isReady,
            state,
            /** Autocompleters */
            /** Actions */
            handleAction,
        };
    },
};
</script>

<style></style>
